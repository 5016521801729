<template>
  <div class="container pt-24 mx-auto flex flex-wrap flex-col md:flex-row items-center justify-center">
    <div class="flex flex-col w-full xl:w-3/5 justify-center lg:items-start overflow-y-hidden">
      <h1 class="w-full text-3xl md:text-5xl text-white opacity-75 font-bold leading-tight text-center pb-8">
        Payment History
      </h1>

      <table class="w-full">
        <thead>
          <tr>
            <th class="text-left pb-2">Name</th>
            <th class="text-left pb-2">Message</th>
            <th class="pb-2">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="payment in currentPage"
            :key="payment.id"
          >
            <td class="text-yellow-300 pb-2">
              <a
                v-if="payment.value('social')"
                :href="payment.value('social')"
                class="underline"
                target="_blank"
              >
                {{ payment.value('name') }}
              </a>
              <template v-else>
                {{ payment.value('name') }}
              </template>
            </td>
            <td class="pb-2">
              {{ payment.value('message') }}
            </td>
            <td class="text-center text-green-400 pb-2">
              {{ payment.value('amountDollars') }}
            </td>
          </tr>

          <div
            v-if="$isLoading('getList')"
            class="loading-overlay"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapLoadableMethods } from 'vue-loadable';
import { mapActions, mapState } from 'vuex';
import { getErrorMessage } from '#lib/Errors';
import { FIELDS } from '#features/payments/lib/models/PaymentModel';

export default {
  name: 'HistoryPage',

  computed: {
    ...mapState('Payments', [
      'currentPage',
    ]),

    /**
     * The fields to display in the list.
     *
     * @return {Array<FieldDisplay>}
     */
    paymentFields() {
      return FIELDS;
    },
  },

  mounted: async function mounted() {
    try {
      await this.getList({ page: 1, per: 100 });
    }
    catch (error) {
      this.$toasted.show(getErrorMessage(error, 'Could not get history.'), {
        type: 'error',
        icon: 'exclamation-circle',
      });
    }
  },

  methods: {
    ...mapLoadableMethods(
      mapActions('Payments', [
        'getList',
      ]),
    ),
  },
};
</script>

<style scoped>
</style>
